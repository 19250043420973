<template>
  <cover>
    <template #background>
      <img src="https://via.placeholder.com/1920x440?text=adomicilio cover" alt="Adomicilio">
    </template>
    <h1>Style Guide</h1>
  </cover>
  <div class="container-box">
    <!-- FONTS -->
    <section class="color-90">
      <h2>Fonts</h2>
      <hr/>
      <h1>H1 Tag</h1>
      <h2>H2 Tag</h2>
      <h3>H3 Tag</h3>
      <h4>H4 Tag</h4>
      <h5>H5 Tag</h5>
      <h6>H6 Tag</h6>
      <p>P Tag</p>
      <p class="alt">P Tag, class "alt"</p>
      <label>Label Tag</label>
    </section>
    <!-- COLORS -->
    <section class="color-90">
      <h2>Colors Classes</h2>
      <hr/>
      <p>
        <span class="color-primary">color-primary </span>
        <span class="color-primary-on">color-primary-on</span>
      </p>
      <p>
        <span class="color-secondary">color-secondary </span>
        <span class="color-secondary-on">color-secondary-on</span>
      </p>
      <p>
        <span class="color-00">color-00 </span>
        <span class="color-10">color-10 </span>
        <span class="color-20">color-20 </span>
        <span class="color-40">color-40 </span>
        <span class="color-60">color-60 </span>
        <span class="color-90">color-90</span>
      </p>
      <p>
        <span class="color-success-10">color-success-10 </span>
        <span class="color-success-50">color-success-50 </span>
        <span class="color-success-90">color-success-90 </span>
      </p>
      <p>
        <span class="color-error-10">color-error-10 </span>
        <span class="color-error-50">color-error-50 </span>
        <span class="color-error-90">color-error-90 </span>
      </p>
    </section>
    <!-- BACKGROUNDS -->
    <section class="color-90">
      <h2>Backgrounds</h2>
      <hr/>
      <p class="color-00">
        <span class="bg-primary">bg-primary </span>
        <span class="bg-primary-on">bg-primary-on </span>
        <span class="bg-primary-opacity">bg-primary-opacity </span>
      </p>
      <p class="color-00">
        <span class="bg-secondary">bg-secondary </span>
        <span class="bg-secondary-on">bg-secondary-on </span>
        <span class="bg-secondary-opacity">bg-secondary-opacity </span>
      </p>

      <p class="color-00">
        <span class="bg-opacity-90">bg-opacity-90 </span>
        <span class="bg-opacity-linear">bg-opacity-linea </span>
        <span class="bg-color-00 color-90">bg-color-00 </span>
        <span class="bg-color-20 color-90">bg-color-20 </span>
        <span class="bg-color-40 color-90">bg-color-40 </span>
        <span class="bg-color-60 color-00">bg-color-60 </span>
        <span class="bg-color-90 color-00">bg-color-90 </span>
      </p>
      <p>
        <span class="bg-success-10">bg-success-10 </span>
        <span class="bg-success-50">bg-success-50 </span>
        <span class="bg-success-90">bg-success-90 </span>
      </p>
      <p>
        <span class="bg-error-10">bg-error-10 </span>
        <span class="bg-error-50">bg-error-50 </span>
        <span class="bg-error-90">bg-error-90 </span>
      </p>
    </section>
    <!-- BUTTONS -->
    <section class="color-90">
      <h2>Buttons</h2>
      <hr/>
      <button class="ml-1">Tag button, .btn, .btn-primary</button>
      <button class="btn-secondary ml-1">.btn-secondary</button>
      <button class="ml-1">Button with span <span>01</span></button>
      <button class="ml-1"><img src="https://via.placeholder.com/20x20?text=adomicilio"
                                alt="A domicilio"/>Button with
        Image
      </button>
      <span class="ml-1">
                        <button class="ml-1 btn-alt btn-image-only">
                        <img src="https://via.placeholder.com/20x20?text=adomicilio" alt="Only Image"/>
        </button>
        <span>.btn-image-only</span>
                    </span>
      <button class="btn-alt ml-1">.btn-extra</button>
      <button class="btn-white-primary ml-1">.btn-white-primary</button>
      <button class="btn-white-secondary ml-1">.btn-white-secondary</button>
      <label>
        Label Input
        <input type="text" placeholder="Placeholder"/>
      </label>
      <label>
        Label Error
        <input class="error" type="text" placeholder="Placeholder"/>
        <p class="color-error-90">Esto es un error</p>
      </label>
    </section>
    <!-- Cards -->
    <section>
      <h2>Cards & Templates</h2>
      <div>
        <h4>grid-columns-4</h4>
        <div class="grid-columns-4">

        </div>
        <div class="grid-columns-2">
          <div class="card horizontal">
            <div class="card-content">
              <div class="img">
                <img src="https://via.placeholder.com/200x200?text=adomicilio"
                     alt="A domicilio"/>
                <a href="/" class="btn btn-seconday-white">Ver más</a>
                <button class="btn-icon">
                  <svg width="20" height="19" viewBox="0 0 20 19" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.5 0C12.76 0 11.09 0.81 10 2.09C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.42 0 5.5C0 9.28 3.4 12.36 8.55 17.04L10 18.35L11.45 17.03C16.6 12.36 20 9.28 20 5.5C20 2.42 17.58 0 14.5 0ZM10.1 15.55L10 15.65L9.9 15.55C5.14 11.24 2 8.39 2 5.5C2 3.5 3.5 2 5.5 2C7.04 2 8.54 2.99 9.07 4.36H10.94C11.46 2.99 12.96 2 14.5 2C16.5 2 18 3.5 18 5.5C18 8.39 14.86 11.24 10.1 15.55Z"
                      fill="white"/>
                  </svg>
                </button>
              </div>
              <div class="info">
                <p class="label color-secondary"><a href="/">Nombre Producto</a></p>
                <p class="alt">50g Producto de pruebas, pero seguramente delicioso, con
                  tortillas
                  azules de maíz.</p>
                <h4 class="color-primary">$50.00</h4>
                <a href="/" class="btn-alt bg-primary hover-bg-primary color-00">Ver
                  Variantes: $40 - $70</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Modals -->
    <section>
      <h2>Dialog</h2>
      <hr>
      <button @click="showModal">Open Dialog</button>
      <Dialog v-model:visible="isVisible">
        <template #header>
          <h2 class="h1">Header</h2>
        </template>
        <img src="https://via.placeholder.com/400x400?text=adomicilio"
             alt="A domicilio"/>
        <template #footer>
          <button>Si, Registrarme</button>
          <button class="btn-clean color-60" @click="hideModal"><u>Cancelar</u></button>
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import Dialog from 'primevue/dialog'
import Cover from '@/components/Cover'

export default {
  components: {
    Dialog,
    Cover
  },
  data () {
    return {
      isVisible: false
    }
  },
  methods: {
    showModal () {
      this.isVisible = true
    },
    hideModal () {
      this.isVisible = false
    }
  }
}

</script>
